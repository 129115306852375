

































import { defineComponent } from '@vue/composition-api'
import { getPhoto, htmlfyJsonString } from '../helpers'
export default defineComponent({
  name: 'StepCard',
  props: {
    step: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    // Links to why 4% or 4.9% commented out until we get the blurb
    // const displayLink = ref(false)

    // Uncomment when we get the 4% links
    // getLink() {
    //   return require(props.step.link);
    // }
    // const hasLink = () => {
    //   if (props.step.link.length > 0 && props.step.linkText.length > 0) {
    //     displayLink.value = true
    //   } else {
    //     displayLink.value = false
    //   }
    // }
    return {
      // methods
      getPhoto,
      htmlfyJsonString
    }
  }
})
