


















































import { defineComponent } from '@vue/composition-api'
import StepCard from '../components/StepCard.vue'
import stepsData from '../data/steps.json'

export default defineComponent({
  name: 'homepage',
  components: {
    StepCard
  },
  setup() {
    // todo: more datalayer
    // startDataLayer() {
    //   dataLayer = [
    //     {
    //       pageCategory: 'checkout'
    //     }
    //   ]
    //   dataLayer.push({
    //     event: 'exploreMyImpact',
    //     ecommerce: {
    //       checkout: {
    //         actionField: { step: 1 }
    //       }
    //     }
    //   })
    // }

    return {
      steps: stepsData
    }
  }
})
